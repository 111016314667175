<template>
  <div class="bind-phone">
    <header class="page-header">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <span>手机绑定</span>
      <span></span>
    </header>
    <main class="page-body">
      <div class="form-field">
        <div class="label">当前手机</div>
        <van-field :value="$store.getters.userInfo.tel" readonly></van-field>
      </div>
      <div class="form-field">
        <div class="label">登录密码</div>
        <password-field
          v-model="password"
          clearable
          placeholder="请输入密码"
        ></password-field>
      </div>
      <div class="form-field">
        <div class="label">新的手机</div>
        <van-field
          v-model="phone"
          clearable
          placeholder="请输入手机号码"
        ></van-field>
      </div>
      <div class="flexbox align-center form-field">
        <van-field
          v-model="code"
          type="digit"
          clearable
          :maxlength="6"
          placeholder="请输入验证码"
        >
        </van-field>
        <van-button
          type="info"
          class="code-btn"
          :disabled="!phone || counting"
          @click="onSend"
        >
          <van-count-down
            :time="60000"
            v-if="counting"
            @finish="counting = false"
          >
            <template #default="timeData">
              <span class="block">重新获取({{ timeData.seconds }})</span>
            </template>
          </van-count-down>
          <span v-else>获取验证码</span>
        </van-button>
      </div>
      <div class="form-field flexbox align-center">
        <van-field
          v-model="captcha"
          clearable
          placeholder="请输入图形验证码"
        ></van-field>
        <img id="img-checkcode1" :src="imgCodeUrl" width="95" height="35" />
      </div>
      <van-button
        class="submit"
        type="info"
        @click="onSubmit"
        :disabled="!valid"
      >
        确 定
      </van-button>
    </main>
  </div>
</template>
<script>
import { sendSMSCode, bindPhone } from '@/api/account.js';
import { BASE_URL } from '@/api/index.js';
export default {
  data() {
    return {
      password: '',
      phone: '',
      code: '',
      captcha: '',
      counting: false,
      imgCodeUrl: BASE_URL + '/customer/GetVerifyImg',
    };
  },
  computed: {
    valid() {
      return this.phone && this.code && this.password && this.captcha;
    },
  },
  methods: {
    onSend() {
      this.counting = true;
      sendSMSCode({ tel: this.username }).then(() => {
        this.$toast.success('发送成功');
      });
    },
    onSubmit() {
      bindPhone({
        tel: this.phone,
        code: this.code,
        password: this.password,
        captcha: this.captcha,
      }).then(res => {
        if (res.Result) {
          this.$router.go(-1);
        } else {
          this.$toast.fail(res.Info);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/_var';
.bind-phone {
  background-color: #fff;
}
.page-body {
  padding: rem(60px);
}
.page-header {
  font-size: 21px;
}
.label {
  font-size: 16px;
  margin-bottom: rem(30px);
}
.van-field {
  border-radius: rem(25px);
  background-color: #f7f7fc;
  font-size: 16px;
  padding-top: rem(24px);
  padding-bottom: rem(24px);
}
.form-field {
  margin-bottom: rem(40px);
}
.submit {
  height: rem(100px);
  line-height: rem(100px);
  width: 100%;
  border-radius: rem(50px);
  margin-top: rem(156px);
  font-size: 16px;
}
.van-count-down {
  color: #fff;
}
.code-btn {
  border-radius: rem(25px);
  margin-left: rem(20px);
  white-space: nowrap;
  color: #fff;
}
</style>
